<template>
  <div class="affiliate-container">
    <b-row>
      <b-col class="affiliate-container__col">
        <div class="filter_date">
          <FilterDatepicker :onFilter="filter" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4" class="affiliate-container__col">
        <div class="panel">
          <div class="panel__content">
            <div class="panel__header">
              <h2 class="panel__title panel__title--minor">
                <b>Top departamentos</b>
              </h2>
            </div>
            <div class="panel__body">
              <TopCategories />
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="8" class="affiliate-container__col">
        <div class="panel">
          <div class="panel__content">
            <div class="panel__header">
              <h2 class="panel__title panel__title--minor">
                <b>Detalhamento</b>
              </h2>
            </div>
            <div class="panel__body">
              <CategoriesTable />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import TopCategories from "@/components/categories/TopCategories.vue";
import CategoriesTable from "@/components/categories/CategoriesTable.vue";
import { listarCategoriasPeriodo } from "@/api/categories/";
export default {
  data() {
    return {
      dateFrom: null,
      dateTo: null,
      pageNumber: 1,
    };
  },
  components: {
    FilterDatepicker,
    TopCategories,
    CategoriesTable,
  },
  methods: {
    listarCategoriasPeriodo,
    filter(data) {
      this.dateFrom = data.start;
      this.dateTo = data.end;
      this.listarItems();
    },
    listarItems() {
      let from = this.dateFrom ? this.dateFrom : null;
      let to = this.dateTo ? this.dateTo : null;
      this.listarCategoriasPeriodo(this.pageNumber, 15, from, to);
    },
  },
  mounted() {
    this.listarItems();
  },
};
</script>

<style lang="scss" scoped>
.affiliate-container {
  &__col {
    padding: 10px;
  }
}
</style>
