<template>
	<div class="table">
		<b-table
			:items="items"
			:fields="fields"
			>
		</b-table>
	</div>
</template>

<script>
import { numberToReal } from "@/utils/money";
export default {
	data() {
		return {
			fields: [
				{
					key: 'categoriesNames',
					label: 'Categoria',
					sortable: true
				},
				{
					key: 'valor_vendido',
					label: 'Total vendido',
					sortable: true,
					formatter: value => {
						return numberToReal(value)
					}
				},
			]
		}
	},
	methods: {
	},
	computed: {
		items() {
      return this.$store.state.categories.viewData;
		}
	}
};
</script>

<style lang="scss" scoped>
.table {
  
}
</style>
