<template>
	<div class="top-table">
		<b-table
			:items="items"
			:fields="fields"
			fixed
			>
		</b-table>
	</div>
</template>

<script>
import { numberToReal } from "@/utils/money"; 
export default {
	data() {
		return {
			fields: [
				{
					key: 'categoriesNames',
					label: 'Nome'
				},
				{
					key: 'valor_vendido',
					label: 'Receita',
					formatter: value => {
						return numberToReal(value)
					}
				}
			]
		}
	},
	methods: {
	},
	computed: {
		items() {
      return this.$store.state.categories.viewData.slice(0, 5);
		}
	}
};
</script>

<style lang="scss" scoped>
.top-table {
	table.b-table {
		tr {
			th:last-child,
			td:last-child {
				text-align: right !important;
			}
		}
	}
}
</style>
